import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Form, Button } from "react-bootstrap";
import { DatePicker } from "react-rainbow-components";
import TagsInput from "./../Components/TagsInput";
import axios from "axios";
import { API_BASE_URL } from "./../config";

function ExpenseModal(props) {
	// Items that has been passed down as props from expense/group expense
	const {
		show,
		handleClose,
		onSaveExpense,
		expense,
		setExpense,
		availableTabs,
		isGroupPage,
		friends,
		setFriends,
		allGroups,
		currentUser,
		groupId,
	} = props;

	// If the user is not in the friends list, add them
	const isUserInFriends = friends.some(
		(friend) => friend.id === currentUser.id
	);
	if (!isUserInFriends) {
		const updatedFriends = [...friends, currentUser];
		setFriends(updatedFriends);
	}

	// Initializations of variables to be used
	const defaultActiveKey = availableTabs.includes("individual")
		? "individual"
		: "group";
	const [activeTab, setActiveTab] = useState(defaultActiveKey);
	const [showSplitOptions, setShowSplitOptions] = useState(false);
	const [splitType, setSplitType] = useState("equal");
	const accessToken = localStorage.getItem("accessToken");

	// Empty form of the modal
	const initialExpense = {
		title: "",
		amount: 0,
		paidBy: "",
		youLent: "",
		date: new Date(),
		category: "",
		recurring: "",
	};
	const [groupInfo, setGroupInfo] = useState(groupId);
	const [groupMembers, setGroupMembers] = useState([]);

	// This set a timer waiting for the information of friends being passed down ready before loading it into group members
	useEffect(() => {
		const timer = setTimeout(() => {
			if (friends && friends.length > 0) {
				setGroupMembers([...friends]);
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, [friends]);

	// This is mainly use for the individual page where if user enter in the people who is personally involve with them, it will load into "group members"
	const [selectedNames, setSelectedNames] = useState(
		friends.map((member) => member.fullname)
	);
	useEffect(() => {
		setGroupMembers(
			friends.filter(
				(member) =>
					selectedNames.includes(member.fullname) || member.id == currentUser.id
			)
		);
	}, [selectedNames]);

	// Once the info of group members for this expense is ready, the splitting type arrays will be loaded with initial values
	const [selectedMembers, setSelectedMembers] = useState([]);
	const [percentages, setPercentages] = useState([]);
	const [shares, setShares] = useState([]);
	const [customAmounts, setCustomAmounts] = useState([]);
	useEffect(() => {
		setSelectedMembers(groupMembers.map(() => true));
		setPercentages(new Array(groupMembers.length).fill(0));
		setShares(new Array(groupMembers.length).fill(0));
		setCustomAmounts(new Array(groupMembers.length).fill(0));
	}, [groupMembers]);

	// Handle the button to show splitting options
	const handleToggleSplitOptions = () => {
		setShowSplitOptions((prev) => !prev);
	};
	const [submitted, setSubmitted] = useState(false);

	// Validate the form
	const validateForm = () => {
		return (
			expense.title.trim() !== "" &&
			expense.amount > 0 &&
			expense.date != null &&
			expense.category.trim() !== "" &&
			expense.recurring.trim() !== ""
		);
	};

	/**
	 * Handle what tab is being selected as in personal expense there are individual and group tab
	 * @param {the tab being selected} key
	 */
	const handleTabSelect = (key) => {
		setActiveTab(key);
	};

	/**
	 * Calculate the amount the current user should pay based on the split type
	 * @param {array of members involved} involved
	 * @returns how much the current user will be lenting to other people
	 */
	const calculateYouLent = (involved) => {
		let currentUserAmountOwed = 0;
		const currentUserIndex = involved.findIndex(
			(member) => member === currentUser.id
		);
		if (currentUserIndex !== -1) {
			switch (splitType) {
				case "equal":
					const totalSelected = selectedMembers.filter(Boolean).length;
					currentUserAmountOwed =
						totalSelected > 0 ? expense.amount / totalSelected : 0;
					break;
				case "percentage":
					currentUserAmountOwed =
						(expense.amount * percentages[currentUserIndex]) / 100;
					break;
				case "proportion":
					const totalShares = shares.reduce((total, curr) => total + curr, 0);
					currentUserAmountOwed =
						totalShares > 0
							? (expense.amount * shares[currentUserIndex]) / totalShares
							: 0;
					break;
				case "custom":
					currentUserAmountOwed = customAmounts[currentUserIndex];
					break;
				default:
					currentUserAmountOwed = 0;
			}
		}

		// Calculate the amount 'youLent'
		let youLent = 0;
		if (expense.paidBy === currentUser.fullname) {
			// If the current user paid the expense
			youLent = expense.amount - currentUserAmountOwed;
		} else {
			// If someone else paid the expense
			youLent = -currentUserAmountOwed; // Negative because the current user owes this amount
		}

		return parseFloat(youLent.toFixed(2));
	};

	/**
	 * Determine how much each member is paying based on the split type
	 * @returns an array with custom object of how much a user paying and their id
	 */
	const splitBetween = () => {
		return groupMembers
			.map((member, index) => {
				if (!selectedMembers[index]) {
					return null; // Skip members who are not selected
				}

				let amountOwed;
				switch (splitType) {
					case "equal":
						const totalSelected = selectedMembers.filter(Boolean).length;
						amountOwed = totalSelected > 0 ? expense.amount / totalSelected : 0;
						break;
					case "percentage":
						amountOwed = (expense.amount * percentages[index]) / 100;
						break;
					case "proportion":
						const totalShares = shares.reduce((total, curr) => total + curr, 0);
						amountOwed =
							totalShares > 0
								? (expense.amount * shares[index]) / totalShares
								: 0;
						break;
					case "custom":
						amountOwed = customAmounts[index];
						break;
					default:
						amountOwed = 0;
				}
				return {
					user: member.id,
					amount: parseFloat(amountOwed.toFixed(2)),
				};
			})
			.filter((entry) => entry && entry.amount > 0);
	};

	/**
	 * Handle posting to database and validation when the user click save
	 */
	const handleSave = () => {
		const GROUP_TAB_KEY = "group";
		const isGroupTabSelected = activeTab === GROUP_TAB_KEY;
		const isGroupPage = isGroupTabSelected;
		setSubmitted(true);

		// Determine who is involved
		let involvedMembers = [];
		for (let index = 0; index < groupMembers.length; index++) {
			const member = groupMembers[index];
			if (selectedMembers[index]) {
				involvedMembers.push(member.id);
			}
		}
		// Create a new expense object
		let newExpense = {
			title: expense.title,
			involve: involvedMembers,
			date: expense.date,
			amount: expense.amount,
			isGroup: isGroupPage,
			group: isGroupPage ? groupInfo : "",
			paidBy: expense.paidBy,
			splitBetween: splitBetween(),
			recurring: expense.recurring,
			category: expense.category,
			youLent: calculateYouLent(involvedMembers),
		};

		axios
			.post(`${API_BASE_URL}/api/expense`, newExpense)
			.then((response) => {
				setExpense(response.data);
			})
			.catch((error) => {
				console.error("Error adding new expense:", error);
			});
		if (validateForm()) {
			onSaveExpense(newExpense);
			handleClose(true);
			setSubmitted(false);
			setExpense(initialExpense);
		}
	};

	/**
	 * Handle calculations specifically towards the "custom" splitting option and update array to use for rendering
	 * @param {the index of the person that is being changed} index
	 * @param {the value that it changing into} value
	 */
	const handleCustomAmountChange = (index, value) => {
		const updatedCustomAmounts = customAmounts.map((amount, i) =>
			i === index ? parseFloat(value) || 0 : amount
		);
		setCustomAmounts(updatedCustomAmounts);
	};

	/**
	 * Calculate the remaining money when using the "custom" splitting option
	 * @returns the remaining amount
	 */
	const calculateRemainingAmount = () => {
		const totalCustomAmounts = customAmounts.reduce(
			(sum, amount) => sum + amount,
			0
		);
		return expense.amount - totalCustomAmounts;
	};

	/**
	 * Handle when a member is click in the splitting option
	 * @param {index of member} index
	 */
	const handleMemberClick = (index) => {
		setSelectedMembers(
			selectedMembers.map((selected, i) => (i === index ? !selected : selected))
		);
	};

	/**
	 * Handle calculations specifically towards the "percentage" splitting option and update array to use for rendering
	 * @param {the index of the person that is being changed} index
	 * @param {the value that it changing into} value
	 */
	const handlePercentageChange = (index, value) => {
		const percentageValue = parseFloat(value);
		const percentage = isNaN(percentageValue)
			? 0
			: Math.max(0, Math.min(100, percentageValue));
		setPercentages(percentages.map((p, i) => (i === index ? percentage : p)));
	};

	/**
	 * Handle calculations specifically towards the "shares" splitting option and update array to use for rendering
	 * @param {the index of the person that is being changed} index
	 * @param {the value that it changing into} value
	 */
	const handleSharesChange = (index, value) => {
		const sharesValue = parseInt(value, 10);
		const newShares = isNaN(sharesValue) ? shares[index] : sharesValue;
		setShares(shares.map((s, i) => (i === index ? newShares : s)));
	};

	/**
	 * This render the members and how much they paying. It also responsive to what user choose or entered.
	 * @returns the corresponding render based on split type
	 */
	const renderMembers = () => {
		if (splitType === "equal") {
			const totalSelected = selectedMembers.filter(Boolean).length;
			const individualAmount =
				totalSelected > 0 ? (expense.amount / totalSelected).toFixed(2) : 0;

			return groupMembers.map((member, index) => {
				const isSelected = selectedMembers[index];
				const memberStyle = isSelected
					? { backgroundColor: "#D6EAF8", color: "#21618C" }
					: {};

				return (
					<div
						key={member.fullname}
						className="member-item"
						onClick={() => handleMemberClick(index)}
						style={{
							cursor: "pointer",
							padding: "5px",
							margin: "10px auto",
							border: "1px solid #ddd",
							width: "60%",
							textAlign: "center",
							borderRadius: "4px",
							...memberStyle,
						}}
					>
						{member.fullname} {isSelected && `pays: $${individualAmount}`}
					</div>
				);
			});
		} else if (splitType === "proportion") {
			const totalShares = shares.reduce((total, current) => total + current, 0);

			// If totalShares is 0, then each selected member should split the bill equally
			const defaultShares = selectedMembers.filter(
				(isSelected) => isSelected
			).length;
			const defaultShareValue =
				defaultShares > 0 ? expense.amount / defaultShares : 0;

			return groupMembers.map((member, index) => {
				const isSelected = selectedMembers[index];
				const memberStyle = isSelected
					? { backgroundColor: "#D6EAF8", color: "#21618C" }
					: {};

				// If totalShares is 0 and the member is selected, use the defaultShareValue
				const individualAmount =
					totalShares > 0
						? ((expense.amount / totalShares) * shares[index]).toFixed(2)
						: isSelected
							? defaultShareValue.toFixed(2)
							: "0.00";

				return (
					<div
						key={member.fullname}
						className="member-item"
						onClick={() => handleMemberClick(index)}
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							cursor: "pointer",
							padding: "5px",
							margin: "10px auto",
							border: "1px solid #ddd",
							width: "70%",
							borderRadius: "4px",
							...memberStyle,
						}}
					>
						<span>
							{member.fullname} pays: ${individualAmount}
						</span>
						{isSelected && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<input
									type="number"
									placeholder={0}
									onChange={(e) => handleSharesChange(index, e.target.value)}
									onClick={(e) => e.stopPropagation()}
									disabled={!isSelected}
									style={{
										width: "50px",
										marginRight: "5px",
									}}
								/>
								<span>share(s)</span>
							</div>
						)}
					</div>
				);
			});
		} else if (splitType === "custom") {
			const remainingAmount = calculateRemainingAmount(); // Call the function to get the remaining amount

			return (
				<>
					<div style={{ textAlign: "center", margin: "10px 0" }}>
						<strong>{`Amount remaining: $${remainingAmount.toFixed(
							2
						)}`}</strong>
					</div>
					{groupMembers.map((member, index) => {
						const isSelected = selectedMembers[index];
						const memberStyle = isSelected
							? { backgroundColor: "#D6EAF8", color: "#21618C" }
							: {};

						return (
							<div
								key={member.fullname}
								className="member-item"
								style={{
									cursor: "pointer",
									padding: "5px",
									margin: "10px auto",
									border: "1px solid #ddd",
									width: "70%",
									textAlign: "center",
									borderRadius: "4px",
									...memberStyle,
								}}
								onClick={() => handleMemberClick(index)}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<span>{member.fullname}</span>
									{isSelected && (
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<input
												type="number"
												placeholder={0}
												onChange={(e) =>
													handleCustomAmountChange(index, e.target.value)
												}
												onClick={(e) => e.stopPropagation()}
												disabled={!isSelected}
												style={{ width: "80px" }}
											/>
											<span>$</span>
										</div>
									)}
								</div>
							</div>
						);
					})}
				</>
			);
		} else if (splitType === "percentage") {
			const totalPercentageAssigned = percentages.reduce(
				(total, current) => total + current,
				0
			);
			const remainingPercentage = 100 - totalPercentageAssigned;
			const membersWithoutPercentage = selectedMembers.reduce(
				(count, isSelected, i) =>
					isSelected && percentages[i] === 0 ? count + 1 : count,
				0
			);
			const defaultSplitAmount =
				remainingPercentage > 0 && membersWithoutPercentage > 0
					? (expense.amount * remainingPercentage) /
						100 /
						membersWithoutPercentage
					: 0;

			return groupMembers.map((member, index) => {
				const isSelected = selectedMembers[index];
				const memberStyle = isSelected
					? { backgroundColor: "#D6EAF8", color: "#21618C" }
					: {};
				const individualAmount = isSelected
					? percentages[index] > 0
						? ((expense.amount * percentages[index]) / 100).toFixed(2)
						: // Use the defaultSplitAmount only if the percentage is exactly 0, indicating it hasn't been set yet.
							defaultSplitAmount.toFixed(2)
					: "0.00";

				return (
					<div
						key={member.fullname}
						className="member-item"
						style={{
							cursor: "pointer",
							padding: "5px",
							margin: "10px auto",
							border: "1px solid #ddd",
							width: "70%",
							textAlign: "center",
							borderRadius: "4px",
							...memberStyle,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						onClick={() => handleMemberClick(index)}
					>
						<span>
							{member.fullname} pays: ${individualAmount}
						</span>
						{isSelected && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<input
									type="number"
									placeholder={0}
									onChange={(e) =>
										handlePercentageChange(index, e.target.value)
									}
									onClick={(e) => e.stopPropagation()}
									disabled={!isSelected}
									style={{
										width: "50px",
										marginRight: "5px",
									}}
								/>
								<span>%</span>
							</div>
						)}
					</div>
				);
			});
		}
	};

	/**
	 * Once split is clicked, this will handle the rendering details of each member
	 * @returns the render of members and how much they paying under the splitting option
	 */
	const renderSplitDetails = () => {
		return <div>{renderMembers()}</div>;
	};

	/**
	 * This get the group members when different group is being selected.
	 * @param {the passed in group id} selectedGroupId
	 */
	const handleGroupChange = async (selectedGroupId) => {
		// Update the groupName state
		setGroupInfo(selectedGroupId);

		// Fetch the group members for the selected group
		try {
			const groupData = await axios.get(
				`${API_BASE_URL}/api/groups/${selectedGroupId}`
			);

			const members = groupData.data.members;
			// Update the friends state with the new group members
			const updatedGroupMembers = [];
			// Fetch user data for each member and update the state
			for (const memberId of members) {
				try {
					const userResponse = await axios.get(
						`${API_BASE_URL}/api/users/find/${memberId}`,
						{
							headers: { Authorization: `Bearer ${accessToken}` },
						}
					);
					const userData = userResponse.data;
					const userInfo = {
						id: userData._id,
						fullname: userData.fullname,
					};

					// Update the state with the new user information
					updatedGroupMembers.push(userInfo);
				} catch (error) {
					console.error(
						`Error fetching user information for ID ${memberId}:`,
						error
					);
				}
			}
			setGroupMembers(updatedGroupMembers);
		} catch (error) {
			console.error("Error fetching group members:", error);
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Add Expense</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Tabs activeKey={activeTab} onSelect={handleTabSelect}>
					{/* When available tab is individual */}
					{availableTabs.includes("individual") && (
						<Tab eventKey="individual" title="Individual">
							<Form.Label>Select Members</Form.Label>
							<TagsInput
								allowedNames={friends.map((member) => member.fullname)}
								setMembers={setSelectedNames}
							/>
						</Tab>
					)}
					{/* When available tab is group */}
					{availableTabs.includes("group") && (
						<Tab eventKey="group" title="Group">
							<Form.Group controlId="groupSelection">
								<Form.Label>Select Group for Expense</Form.Label>
								<Form.Control
									as="select"
									value={expense.groupId}
									onChange={(e) => handleGroupChange(e.target.value)}
								>
									<option value="">Select your group...</option>
									{allGroups.map((group) => (
										<option key={group._id} value={group._id}>
											{group.name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
						</Tab>
					)}
				</Tabs>
				<form>
					<Form.Group controlId="expensePayer">
						<Form.Label>Paid by</Form.Label>
						<Form.Control
							as="select"
							value={expense.paidBy}
							onChange={(e) =>
								setExpense({
									...expense,
									paidBy: e.target.value,
								})
							}
							required
						>
							<option value="">Select who paid...</option>
							{groupMembers.map((member) => (
								<option key={member.id} value={member.fullname}>
									{member.fullname}
								</option>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group controlId="expenseName">
						<Form.Label>Expense Name</Form.Label>
						<Form.Control
							type="text"
							value={expense.title}
							onChange={(e) =>
								setExpense({
									...expense,
									title: e.target.value,
								})
							}
							placeholder="Enter expense name"
							isInvalid={submitted && !expense.title}
							required
						/>
						<Form.Control.Feedback type="invalid">
							Please provide a name for the expense.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="expenseAmount">
						<Form.Label>Amount</Form.Label>
						<Form.Control
							type="number"
							value={expense.amount}
							onChange={(e) =>
								setExpense({
									...expense,
									amount: e.target.value,
								})
							}
							placeholder="Enter amount"
							isInvalid={submitted && !expense.amount}
							required
						/>
						<Form.Control.Feedback type="invalid">
							Please provide a name for the expense.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="expenseDate">
						<Form.Label>Date</Form.Label>
						<DatePicker
							id="datePicker-1"
							value={expense.date}
							onChange={(date) =>
								setExpense({
									...expense,
									date: new Date(date),
								})
							}
							alt="Cancel"
						/>
					</Form.Group>

					<Form.Group controlId="Category">
						<Form.Label>Category</Form.Label>
						<Form.Control
							as="select"
							value={expense.category}
							onChange={(e) =>
								setExpense({
									...expense,
									category: e.target.value,
								})
							}
							isInvalid={submitted && !expense.category}
							required
						>
							<option value="">Select...</option>
							<option value="groceries">Groceries</option>
							<option value="utilities">Utilities</option>
							<option value="rent">Rent</option>
							<option value="transportation">Transportation</option>
							<option value="food">Food</option>
							<option value="other">Other</option>
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							Please provide a category for the expense.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="RecurringExpense">
						<Form.Label>Recurring Expense</Form.Label>
						<Form.Control
							as="select"
							value={expense.recurring}
							onChange={(e) =>
								setExpense({
									...expense,
									recurring: e.target.value,
								})
							}
							isInvalid={submitted && !expense.recurring}
							required
						>
							<option value="">Select...</option>
							<option value="non-recurring">Non-recurring</option>
							<option value="daily">Daily</option>
							<option value="weekly">Weekly</option>
							<option value="monthly">Monthly</option>
							<option value="yearly">Yearly</option>
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							Please provide a recurring for the expense.
						</Form.Control.Feedback>
					</Form.Group>

					<Button
						onClick={handleToggleSplitOptions}
						variant="outline-secondary"
						className="mt-3"
					>
						{showSplitOptions
							? "Hide Splitting Options"
							: "Show Splitting Options"}
					</Button>

					{/* Splitting options section */}
					{showSplitOptions && (
						<div className="splitting-options mt-3">
							<Form.Group controlId="splitType">
								<Form.Label>Split Type</Form.Label>
								<Form.Control
									as="select"
									value={splitType}
									onChange={(e) => setSplitType(e.target.value)}
								>
									<option value="equal">Equally</option>
									<option value="percentage">Percentage</option>
									<option value="proportion">Proportion</option>
									<option value="custom">Custom</option>
								</Form.Control>
								{renderSplitDetails()}
							</Form.Group>
						</div>
					)}
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSave}>
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default ExpenseModal;

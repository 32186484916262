import Container from "react-bootstrap/Container";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import Profile from "../pages/navbar/Profile";
import Addfriend from "../pages/navbar/Addfriend";
import Settings from "../pages/navbar/Settings";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../static/spendSmart_logo.png";

function NavBar() {
	const navigate = useNavigate();

	const [profileModal, handleProfileModal] = useState(false);
	const [addfriendModal, handleAddfriendModal] = useState(false);
	const [settingModal, handleSettingModal] = useState(false);

	const handleHideModal = useCallback(() => {
		handleProfileModal(false);
		handleAddfriendModal(false);
		handleSettingModal(false);
	}, []);

	const handleLogout = () => {
		googleLogout();
		localStorage.removeItem("accessToken");
		navigate("/");
	};

	return (
		<Navbar expand="lg" className="bg-body-tertiary">
			<Container fluid="lg">
				<Navbar.Brand as={Link} to="/home">
					{" "}
					<img
						src={logo}
						alt="SpendSmart Logo"
						style={{ width: "200px", height: "auto" }}
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link as={Link} to="/expense">
							Expense
						</Nav.Link>
						<Nav.Link as={Link} to="/social">
							Social
						</Nav.Link>
						{/* <Nav.Link as={Link} to="/data">
							Data
						</Nav.Link> */}
					</Nav>
					<Nav>
						<NavDropdown title="Account" id="basic-nav-dropdown">
							<NavDropdown.Item onClick={() => handleProfileModal(true)}>
								Profile
							</NavDropdown.Item>
							<Profile show={profileModal} onHide={handleHideModal} />
							<NavDropdown.Item onClick={() => handleAddfriendModal(true)}>
								Add Friend
							</NavDropdown.Item>
							<Addfriend show={addfriendModal} onHide={handleHideModal} />
							{/* <NavDropdown.Item onClick={() => handleSettingModal(true)}>Settings</NavDropdown.Item>
              <Settings
                show={settingModal}
                onHide={handleHideModal} /> */}
							<NavDropdown.Divider />
							<NavDropdown.Item as={Link} to="/signin" onClick={handleLogout}>
								Sign Out
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default NavBar;

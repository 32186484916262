import { FormControl, Modal, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import ListGroup from "react-bootstrap/ListGroup";
import { Plus } from "react-bootstrap-icons";
import { API_BASE_URL } from "../../config";

function Addfriend(props) {
  const accessToken = localStorage.getItem("accessToken");
  var searchQuery;

  const [friendlist, setFriendlist] = useState([]);

  async function searchUsers(query) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/search`, {
        params: { query },
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setFriendlist(response.data);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  function handleSearchInputChange(event) {
    searchQuery = event.target.value;
  }

  async function addfriend(friendID) {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/users/addfriend/${friendID}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.status === 200) {
        window.alert("Friend added successfully");
      } else if (response.status === 400) {
        window.alert(
          "Invalid userId or friendId or friend already exists in the friendList"
        );
      } else if (response.status === 404) {
        window.alert("User not found");
      }
    } catch (error) {
      console.error("Error adding friend:", error);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Friend</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl
          type="text"
          placeholder="Friend's name or username"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Button onClick={() => searchUsers(searchQuery)}>Search</Button>
        </div>

        <ListGroup as="ul">
          {friendlist.map((friend) => {
            return (
              <ListGroup.Item as="li" key={friend._id}>
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    {friend.fullname}{" "}
                    <span style={{ color: "#339aff" }}>#{friend.username}</span>
                  </p>
                  <Button onClick={() => addfriend(friend._id)}>
                    <Plus />
                  </Button>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default Addfriend;

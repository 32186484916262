import { Card, Button, Container, Row, Col, CardTitle } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";
import { allGroupsBarOptions } from "./chartOptions";
import Highcharts from "highcharts";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "./../config";

function Home() {
	//Expenses window ----------------------------------------------------
	const [userId, setUserId] = useState("");
	const [recentExpenses, setRecentExpenses] = useState([]);
	const [weekExpense, setWeekExpense] = useState();
	const accessToken = localStorage.getItem("accessToken");

	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/users`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then((response) => {
				setUserId(response.data._id);
				setWeekExpense(response.data.weekSpending);
			})
			.catch((error) => {
				console.error("Error fetching friend list:", error);
			});
	}, [accessToken]);

	useEffect(() => {
		const fetchExpenses = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/api/expense?userId=${userId}`
				);
				let expensesData = response.data.map((expense) => ({
					...expense,
					id: expense._id,
				}));
				expensesData.sort((a, b) => new Date(b.date) - new Date(a.date));
				setRecentExpenses(expensesData.slice(0, 2));
			} catch (error) {
				console.error("Error fetching expenses:", error);
			}
		};

		fetchExpenses();
	}, [userId]);
	//This is the end of Expenses' window functions---------------------------------------------------------------

	//This is the start of the Group window functions-------------------------------------------------------------
	const [summaryData, setSummary] = useState([]);
	const [user, setUser] = useState({});

	//fetches the information about the current user
	const fetchUser = async (accessToken) => {
		try {
			const response = await axios.get(`${API_BASE_URL}/api/users`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			});
			setUser(response.data);
		} catch (error) {
			console.error("Error fetching user data:", error);
		}
	};

	//updates the access token
	useEffect(() => {
		if (accessToken) {
			fetchUser(accessToken);
		}
	}, [accessToken]);

	//fetches the data from the groupRoutes to set the groups window summary based on the user
	useEffect(() => {
		if (user && user._id) {
			axios
				.get(`${API_BASE_URL}/api/groups/summary/${user._id}`)
				.then((response) => {
					// handle success
					setSummary(response.data);
				})
				.catch((error) => {
					// handle error
					console.log(error);
				});
		}
	}, [user]);

	//This is the end of the Group window functions---------------------------------------------------------------

	//This just puts in the graph container
	useEffect(() => {
		Highcharts.chart("bar-chart-container", allGroupsBarOptions);
	}, []);

	const [allGroups, setGroup] = useState([]);

	//get all the groups from mongo
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/groups`)
			.then((response) => {
				setGroup(
					response.data.map((groups) => ({
						...groups,
						id: groups._id, //rename the _id of mongoose to id for consistency
					}))
				);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	//calculate the group names for the x-axis and the total money lent in each
	//group for the y-axis
	useEffect(() => {
		const groupIds = [];
		const totalYouLent = [];
		const groupmap = {};

		allGroups.forEach((group) => {
			const fetchExpenses = async (groupID) => {
				try {
					const response = await axios.get(
						`${API_BASE_URL}/api/expense/${groupID}`
					);
					response.data.forEach((expense) => {
						const groupId = expense.group;
						const index = groupIds.indexOf(groupId);

						if (index === -1) {
							groupIds.push(groupId);
							totalYouLent.push(Math.abs(expense.youLent));
						} else {
							totalYouLent[index] += Math.abs(expense.youLent);
						}
					});
				} catch (error) {
					console.error("Error fetching expenses: ", error);
					throw error;
				}
				const baroptions = allGroupsBarOptions;

				baroptions.xAxis.categories = allGroups.map((group) => group.name);
				baroptions.series[0].data = totalYouLent;

				Highcharts.chart("bar-chart-container", baroptions);
			};
			fetchExpenses(group.id);
		});
	}, [allGroups]);

	//This is the end of Data Window-------------------------------------------------------------
	return (
		<Container fluid="lg">
			<Card className="group_card">
				<Card.Header as="h3" className="group_title">
					Expense
				</Card.Header>
				<Card.Body>
					<Row className="row">
						<Col
							md={3}
							className="mb-3 d-flex flex-column justify-content-center"
						>
							<div className="icon-container mb-2">
								<i className="bi bi-cash-coin" style={{ fontSize: "80px" }}></i>
							</div>
							<Card.Title className="mb-2">
								This week's expenses:{" "}
								<span style={{ fontWeight: "normal" }}>${weekExpense}</span>
							</Card.Title>
							<Card.Title className="mb-3">
								<Button as={Link} to="/expense" variant="outline-info">
									Go to Expense
								</Button>
							</Card.Title>
						</Col>
						<Col md={9} className="mb-3">
							<CardTitle> Recent Activities </CardTitle>
							{recentExpenses.map((expense) => {
								return (
									<Card className="mb-3">
										<Card.Body>
											<Row>
												<Col xs={6}>
													<Card.Title className="group_card_align_left">
														{expense.category === "groceries" && (
															<i
																style={{
																	fontSize: "20px",
																	paddingRight: "10px",
																}}
																className="bi bi-shop"
															></i>
														)}
														{expense.category === "utilities" && (
															<i
																style={{
																	fontSize: "20px",
																	paddingRight: "10px",
																}}
																className="bi bi-thermometer-snow"
															></i>
														)}
														{expense.category === "rent" && (
															<i
																style={{
																	fontSize: "20px",
																	paddingRight: "10px",
																}}
																className="bi bi-houses"
															></i>
														)}
														{expense.category === "transportation" && (
															<i
																style={{
																	fontSize: "20px",
																	paddingRight: "10px",
																}}
																className="bi bi-bus-front"
															></i>
														)}
														{expense.category === "food" && (
															<i
																style={{
																	fontSize: "20px",
																	paddingRight: "10px",
																}}
																className="bi bi-cup-hot"
															></i>
														)}
														{expense.category === "other" && (
															<i
																style={{
																	fontSize: "20px",
																	paddingRight: "10px",
																}}
																className="bi bi-puzzle-fill"
															></i>
														)}
														{expense.title}
													</Card.Title>
												</Col>
												<Col xs={6}>
													<Card.Text>${expense.amount}</Card.Text>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								);
							})}
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<Card className="group_card">
				<Card.Header as="h3" className="group_title">
					Groups
				</Card.Header>
				<Card.Body>
					<Row className="row">
						<Col
							md={3}
							className="mb-3 d-flex flex-column justify-content-center"
						>
							<div className="icon-container mb-2">
								<i
									className="bi bi-people-fill"
									style={{ fontSize: "80px" }}
								></i>
							</div>
							<Card.Title className="mb-2">
								Total Owed:{" "}
								<span style={{ fontWeight: "normal" }}>
									{" "}
									-${Math.abs(summaryData.totalOwe)}
								</span>
							</Card.Title>
							<Card.Title className="mb-3">
								Total Own:{" "}
								<span style={{ fontWeight: "normal" }}>
									${summaryData.totalOwn}
								</span>
							</Card.Title>
							<Card.Title className="mb-3">
								<Button as={Link} to="/social" variant="outline-info">
									Go to Groups
								</Button>
							</Card.Title>
						</Col>
						<Col md={9} className="mb-3">
							<Card className="mb-3">
								<Card.Body>
									<Row>
										<Col xs={6}>
											<Card.Title className="group_card_align_left">
												<i
													style={{
														fontSize: "20px",
														paddingRight: "10px",
													}}
													className="bi bi-grid"
												></i>
												All Groups
											</Card.Title>
											<Card.Text className="group_card_align_left">
												{summaryData.myGroup} groups
											</Card.Text>
										</Col>
										<Col xs={6}>
											<Card.Text>Total Owed: ${summaryData.totalOwe}</Card.Text>
											<Card.Text>Total Own: ${summaryData.totalOwn}</Card.Text>
										</Col>
									</Row>
								</Card.Body>
							</Card>
							<Card className="mb-3">
								<Card.Body>
									<Row>
										<Col xs={6}>
											<Card.Title className="group_card_align_left">
												<i
													style={{
														fontSize: "20px",
														paddingRight: "10px",
													}}
													className="bi bi-bookmark-heart"
												></i>
												Favorite Groups
											</Card.Title>
											<Card.Text className="group_card_align_left">
												{summaryData.myFavorite} groups
											</Card.Text>
										</Col>
										<Col xs={6}>
											<Card.Text>
												Total Owed: ${summaryData.favoriteOwe}
											</Card.Text>
											<Card.Text>
												Total Own: ${summaryData.favoriteOwn}
											</Card.Text>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<Card className="group_card">
				<Card.Header as="h3" className="group_title">
					Data
				</Card.Header>
				<Card.Body>
					<Row className="row">
						<Col
							md={3}
							className="mb-3 d-flex flex-column justify-content-center"
						>
							<div className="icon-container mb-2">
								<i
									className="bi bi-bar-chart-line-fill"
									style={{ fontSize: "80px" }}
								></i>
							</div>
							<Card.Title className="mb-3">
								<Button as={Link} to="/data" variant="outline-info">
									Go to Data
								</Button>
							</Card.Title>
						</Col>
						<Col md={9} className="mb-3">
							<Card className="mb-3">
								<Card.Body>
									<Card.Title className="group_card_align_left">
										How much you spent in each Group
									</Card.Title>
									<div
										id="bar-chart-container"
										style={{
											width: "100%",
											height: "300px",
											marginTop: "60px",
										}}
									></div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
}
export default Home;

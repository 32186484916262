export const allGroupsBarOptions = {
	chart: {
		type: "bar",
	},
	title: {
		text: "Total Money Spent in Each Group",
		margin: 40,
	},
	xAxis: {
		title: {
			text: "Groups",
			margin: 40,
			style: {
				fontSize: "1em",
			},
		},
		categories: [],
	},
	yAxis: {
		title: {
			text: "Amount of Money ($)",
			margin: 40,
			style: {
				fontSize: "1em",
			},
		},
	},
	series: [
		{
			name: "Data",
			showInLegend: false,
			data: [],
		},
	],
};

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Row, Form } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import ToastNotification from "./ToastNotification";
import logo from "../static/spendSmart_logo.png";
import "./Signin.css";
import axios from "axios";
import { API_BASE_URL } from "../config";

// Define the Signin functional component
const Signin = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({ username: "", password: "" });

	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [toastVariant, setToastVariant] = useState("danger");

	const [showPassword, setShowPassword] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	// Function to handle user sign-in
	const signIn = async (event) => {
		event.preventDefault();
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/users/signin`,
				formData
			);
			const token = response.data.token;
			localStorage.setItem("accessToken", token);

			setToastVariant("success");
			setToastMessage("You Are Successfully Logged In!");
			setShowToast(true);
			navigate("/home");
		} catch (error) {
			console.log(error.response);
			setToastVariant("danger");
			setToastMessage(
				error.response?.data?.message || "An error occurred while signing in!"
			);
			setShowToast(true);
		}
	};

	const handleGoogleSuccess = async (credentialResponse) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/users/google-signin`,
				{
					token: credentialResponse.credential,
				}
			);
			const token = response.data.token;
			localStorage.setItem("accessToken", token);

			// Handle successful authentication here
			navigate("/home");
		} catch (error) {
			console.error("Error during Google sign-in", error);
			// Handle errors here
		}
	};
	const handleGoogleFailure = () => {
		setToastVariant("danger");
		setToastMessage("You Are Not Able to login using Google account!");
	};

	return (
		<div>
			<h1 className="web-title">
				<img
					src={logo}
					alt="SpendSmart Logo"
					style={{ width: "200px", height: "auto" }}
				/>
			</h1>
			<div className="container">
				<h1 className="header">Sign In</h1>
				<Form className="centered-form">
					<Form.Group
						as={Row}
						className="mb-3"
						controlId="formPlaintextUsername"
					>
						<Form.Control
							type="text"
							name="username"
							placeholder="Username"
							value={formData.username}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group
						as={Row}
						className="mb-3"
						controlId="formPlaintextPassword"
					>
						<div className="password-wrapper">
							<Form.Control
								type={showPassword ? "text" : "password"}
								name="password"
								placeholder="Password"
								value={formData.password}
								onChange={handleInputChange}
							/>
							<button
								type="button"
								className="toggle-password-btn"
								onClick={togglePasswordVisibility}
								aria-label="Toggle password visibility"
							>
								<i
									className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}
								></i>
							</button>
						</div>
					</Form.Group>
					<Button variant="info" onClick={signIn}>
						Sign In
					</Button>
				</Form>
				<div className="signup-container">
					New?&nbsp;
					<Link to="/signup" style={{ color: "black" }}>
						Sign up now
					</Link>
				</div>

				<div className="or-divider">
					<span className="or-text">or</span>
				</div>

				<div className="google-login-container">
					<GoogleLogin
						onSuccess={handleGoogleSuccess}
						onError={handleGoogleFailure}
					/>
				</div>

				<ToastNotification
					show={showToast}
					message={toastMessage}
					variant={toastVariant}
					onClose={() => setShowToast(false)}
				/>
			</div>
		</div>
	);
};

export default Signin;

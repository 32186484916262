import { MDBFooter } from "mdb-react-ui-kit";

const Footer = () => {
	return (
		<MDBFooter
			className="text-center text-white sticky-footer"
			style={{
				backgroundColor: "rgb(248, 249, 250)",
				position: "fixed",
				left: 0,
				right: 0,
				bottom: 0,
			}}
		>
			<div className="text-center p-3" style={{ color: "rgb(33, 37, 41)" }}>
				&copy; SpendSmart Project {new Date().getFullYear()}. All Rights
				Reserved{" "}
			</div>
		</MDBFooter>
	);
};
export default Footer;

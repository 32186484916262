import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	ListGroup,
	Button,
	FormControl,
	Dropdown,
	Form,
} from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import ExpenseModal from "./../Components/ExpenseModal";
import { DatePicker } from "react-rainbow-components";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";
import "./Expense.css";
import { API_BASE_URL } from "./../config";

function Expense() {
	// Initializations of variables to be used
	const [modalShow, setModalShow] = useState(false);
	const handleModalClose = () => setModalShow(false);
	const handleModalShow = () => {
		setModalShow(true);
		setNewExpenseForm(initialExpense);
	};
	const [timeFrame, setTimeFrame] = useState("monthly");
	const [userInfo, setUserInfo] = useState({});
	const accessToken = localStorage.getItem("accessToken");
	const initialExpense = {
		title: "",
		involve: [],
		date: new Date(),
		amount: "",
		isGroup: true,
		group: "",
		paidBy: "",
		splitBetween: [{ user: "", amount: 0 }],
		recurring: "",
		category: "",
		youLent: 0,
	};

	// Extract all users that is friend to the current user and store in allUser array
	// This also extract the current user
	const [allUser, setAllUser] = useState([]);
	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get(`${API_BASE_URL}/api/users`, {
					headers: { Authorization: `Bearer ${accessToken}` },
				});
				const friends = response.data.friendList;
				setUserInfo({
					id: response.data._id,
					fullname: response.data.fullname,
				});
				const updatedFriends = [];
				for (const userId of friends) {
					try {
						const userResponse = await axios.get(
							`${API_BASE_URL}/api/users/find/${userId}`,
							{
								headers: {
									Authorization: `Bearer ${accessToken}`,
								},
							}
						);
						const userData = userResponse.data;
						const userInfo = {
							id: userData._id,
							fullname: userData.fullname,
						};
						updatedFriends.push(userInfo);
					} catch (error) {
						console.error(
							`Error fetching user information for ID ${userId}:`,
							error
						);
					}
				}
				setAllUser(updatedFriends);
			} catch (error) {
				console.error("Error fetching friend list:", error);
			}
		};

		fetchUsers();
	}, [accessToken]);

	// Get expenses data from mongodb and render them by selected filter order
	const [expenses, setExpenses] = useState([]);
	useEffect(() => {
		const fetchExpenses = async () => {
			if (!userInfo.id) return; // Make sure userInfo.id is valid

			try {
				const response = await axios.get(
					`${API_BASE_URL}/api/expense?userId=${userInfo.id}`
				);
				const expensesData = response.data
					.map((expense) => ({
						...expense,
						id: expense._id, //rename the _id of mongoose to id for consistency
					}))
					.sort((a, b) => new Date(b.date) - new Date(a.date));
				setExpenses(expensesData);
			} catch (error) {
				console.error("Error fetching expenses:", error);
			}
		};

		fetchExpenses();
	}, [userInfo.id]);

	/**
	 * Calculate the amount of total owe and own of the current user
	 * @returns the calulated total owe and own of the user
	 */
	const calculateTotals = () => {
		let totalOwed = 0;
		let totalOwn = 0;
		expenses.forEach((expense) => {
			if (expense.paidBy === userInfo.fullname) {
				totalOwn += expense.youLent; // Total amount others owe the user
			} else {
				totalOwed += expense.youLent; // Total amount the user owes others
			}
		});

		return { totalOwed, totalOwn };
	};

	// Put to database to update the amount of user owe, own, and total spending of the week
	const updateUserInfo = useCallback(async () => {
		const { totalOwed, totalOwn } = calculateTotals();

		const currentWeekExpenses = currWeekExpensesList();
		const weekSpending = totalWeekExpense(currentWeekExpenses);

		try {
			// Update user info in the database
			await axios.put(
				`${API_BASE_URL}/api/users/update/${userInfo.id}`,
				{
					youOwed: totalOwed,
					youOwn: totalOwn,
					weekSpending: weekSpending,
				},
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				}
			);

			// Update user info in state
			setUserInfo((prevUserInfo) => ({
				...prevUserInfo,
				youOwed: totalOwed,
				youOwn: totalOwn,
				weekSpending: weekSpending,
			}));
		} catch (error) {
			console.error("Error updating user info:", error);
		}
	}, [expenses, userInfo.id, accessToken]);

	const addNewExpense = useCallback(async (newExpense) => {
		setExpenses((prevExpenses) => [...prevExpenses, newExpense]);
	}, []);

	//This function put all expenses within the curr week into an array
	const currWeekExpensesList = () => {
		const now = new Date();
		// Reset the hours, minutes, and seconds to 0 for the start of the day
		now.setHours(0, 0, 0, 0);

		let dayOfWeek = now.getDay();
		dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

		// Calculate first and last day of the current week
		const firstDayOfWeek = new Date(now.setDate(now.getDate() - dayOfWeek + 1));
		const lastDayOfWeek = new Date(firstDayOfWeek);
		lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
		// Set the last day of the week to the end of the day
		lastDayOfWeek.setHours(23, 59, 59, 999);

		// Filter expenses for the current week
		const currWeekExpenses = expenses.filter((expense) => {
			const expenseDate = new Date(expense.date);
			return expenseDate >= firstDayOfWeek && expenseDate <= lastDayOfWeek;
		});
		return currWeekExpenses;
	};

	//This function calculate the total expenses of this week
	const totalWeekExpense = (weekExpenses) => {
		let sum = 0;
		weekExpenses.map((expense) => {
			expense.splitBetween.forEach((each) => {
				if (each.user === userInfo.id) {
					sum += each.amount;
				}
			});
		});
		return parseFloat(sum.toFixed(2));
	};

	// Update the user total owe, own, and week spending everytime the user is changed or there are changes to expenses
	useEffect(() => {
		// Check if userInfo.id is available
		if (userInfo.id) {
			updateUserInfo(); // This will run only if userInfo.id is available
		}
	}, [expenses, userInfo.id]);

	// This get all the groups available within the user
	const [allGroups, setGroup] = useState([]);
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/groups?userId=${userInfo.id}`)
			.then((response) => {
				setGroup(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [userInfo]);

	// This set the form when edit the expense
	const [editExpenseId, setEditExpenseId] = useState(null);
	const [editFormData, setEditFormData] = useState({
		title: "",
		date: "",
		value: "",
		paidBy: "",
		youLent: "",
	});

	// Update the expense
	const handleSaveClick = useCallback(
		async (id) => {
			try {
				const updatedData = {
					...editFormData,
					date: new Date(editFormData.date),
				};
				const response = await axios.put(
					`${API_BASE_URL}/api/expense/handleUpdate/${id}`,
					updatedData
				);
				//update locally
				setExpenses((prevExpenses) =>
					prevExpenses.map((expense) =>
						expense.id === id
							? { ...response.data, id: response.data._id }
							: expense
					)
				);
				setEditExpenseId(null);
			} catch (error) {
				console.error("Error updating expense:", error);
			}
		},
		[editFormData]
	);

	// Delete the expense
	const handleDelete = useCallback(async (id) => {
		if (window.confirm("Are you sure you want to delete this expense?")) {
			try {
				await axios.delete(`${API_BASE_URL}/api/expense/${id}`);
				setExpenses((prevExpenses) =>
					prevExpenses.filter((expense) => expense.id !== id)
				);
			} catch (error) {
				console.error("Error deleting expense:", error);
			}
		}
	}, []);

	// This create a new expense form
	const [newExpenseForm, setNewExpenseForm] = useState(initialExpense);
	const handleTimeFrameChange = (e) => {
		setTimeFrame(e);
	};

	// This handle the filter of expenses: week, moth, year
	const groupedExpenses = useMemo(() => {
		return expenses.reduce((acc, expense) => {
			const date = new Date(expense.date);
			let period;

			switch (timeFrame) {
				case "monthly":
					period = date.toLocaleString("default", {
						month: "long",
						year: "numeric",
					});
					break;
				case "yearly":
					period = date.getFullYear().toString();
					break;
				default:
					period = `Week of ${date.getDate()} - ${
						date.getDate() + 7
					}, ${date.getFullYear()}`;
			}

			if (!acc[period]) {
				acc[period] = [];
			}
			acc[period].push(expense);
			return acc;
		}, {});
	}, [expenses, timeFrame]);

	const sortExpensesByDateDesc = (expensesArray) => {
		return expensesArray.sort((a, b) => b.date - a.date);
	};

	const sortedExpenses = useMemo(() => {
		return expenses.sort((a, b) => b.date - a.date);
	}, [expenses]);

	// This handle the edit button to change the form
	const handleEditClick = (expense) => {
		setEditExpenseId(expense.id);
		const formValues = {
			title: expense.title,
			date: expense.date,
			amount: expense.amount,
			paidBy: expense.paidBy,
			youLent: expense.youLent,
			category: expense.category,
			recurring: expense.recurring,
		};
		setEditFormData(formValues);
	};

	// This handle the edited form change
	const handleEditFormChange = (event) => {
		const { name, value } = event.target
			? event.target
			: { name: "date", value: event };
		setEditFormData({
			...editFormData,
			[name]: value,
		});
	};

	// This sort expenses by date
	const sortedPeriods = Object.keys(groupedExpenses).sort((a, b) => {
		const dateA = new Date(a.split(" ").pop());
		const dateB = new Date(b.split(" ").pop());
		return dateB - dateA;
	});

	return (
		<Container>
			<Row>
				<h2>Personal Expense</h2>
			</Row>
			<Row>
				<div className="expenses-header">
					<div className="expenses-week">
						<h5>
							<span>Expenses This Week: ${userInfo.weekSpending}</span>
							<span className="total-own">Total Own: ${userInfo.youOwn}</span>
							<span className="total-owe">
								Total Owe: -${Math.abs(userInfo.youOwed)}
							</span>
						</h5>
					</div>
				</div>
			</Row>
			<Row xs="auto" style={{ marginBottom: "20px" }}>
				<Dropdown onSelect={handleTimeFrameChange}>
					<Dropdown.Toggle variant="info" id="dropdown-timeframe">
						{timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item eventKey="weekly">Weekly</Dropdown.Item>
						<Dropdown.Item eventKey="monthly">Monthly</Dropdown.Item>
						<Dropdown.Item eventKey="yearly">Yearly</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Row>
			<Card>
				<Card.Header>
					<Row>
						<Col xs={1}>Category</Col>
						<Col xs={2}>Title</Col>
						<Col xs={3}>Date</Col>
						<Col xs={1}>Amount</Col>
						<Col xs={2}>Paid By</Col>
						<Col xs={1}>You Lent</Col>
						<Col xs={2}>Actions</Col>
					</Row>
				</Card.Header>
				<ListGroup variant="flush">
					{sortedPeriods.map((period) => (
						<React.Fragment key={period}>
							{Object.entries(groupedExpenses).length !== 0 && (
								<ListGroup.Item bg="secondary" className="text-start">
									<strong>{period}</strong>
								</ListGroup.Item>
							)}
							{groupedExpenses[period].map((expense) =>
								editExpenseId === expense.id ? (
									<ListGroup.Item key={expense.id}>
										<Row>
											<Col xs={1}>
												<Form.Control
													as="select"
													value={editFormData.category}
													onChange={handleEditFormChange}
													name="category"
													required
												>
													<option value="">Select...</option>
													<option value="groceries">Groceries</option>
													<option value="utilities">Utilities</option>
													<option value="rent">Rent</option>
													<option value="transportation">Transportation</option>
													<option value="food">Food</option>
													<option value="other">Other</option>
												</Form.Control>
											</Col>

											<Col xs={2}>
												<FormControl
													type="text"
													name="title"
													value={editFormData.title}
													onChange={handleEditFormChange}
												/>
											</Col>
											<Col xs={3}>
												<DatePicker
													id="datePicker-1"
													value={editFormData.date}
													onChange={handleEditFormChange}
													alt="Cancel"
												/>
											</Col>
											<Col xs={1}>
												<FormControl
													type="number"
													name="amount"
													value={editFormData.amount}
													onChange={handleEditFormChange}
												/>
											</Col>
											<Col xs={2}>
												<Form.Control
													as="select"
													value={editFormData.paidBy}
													onChange={handleEditFormChange}
													name="paidBy"
												>
													{allUser.map((user) => (
														<option key={user.fullname} value={user.fullname}>
															{user.fullname}
														</option>
													))}
												</Form.Control>
											</Col>
											<Col xs={1}>
												<FormControl
													type="number"
													name="youLent"
													value={editFormData.youLent}
													onChange={handleEditFormChange}
												/>
											</Col>
											<Col xs={2} className="text-start">
												<Button
													variant="info"
													onClick={() => handleSaveClick(expense.id)}
												>
													Save
												</Button>
											</Col>
										</Row>
									</ListGroup.Item>
								) : (
									<ListGroup.Item key={expense.id}>
										<Row>
											<Col xs={1}>
												{expense.category === "groceries" && (
													<i className="bi bi-shop"></i>
												)}
												{expense.category === "utilities" && (
													<i className="bi bi-thermometer-snow"></i>
												)}
												{expense.category === "rent" && (
													<i className="bi bi-houses"></i>
												)}
												{expense.category === "transportation" && (
													<i className="bi bi-bus-front"></i>
												)}
												{expense.category === "food" && (
													<i className="bi bi-cup-hot"></i>
												)}
												{expense.category === "other" && (
													<i className="bi bi-puzzle-fill"></i>
												)}
											</Col>
											<Col xs={2} className="text-start">
												{expense.title}
											</Col>
											<Col xs={3}>
												{expense.date
													? new Date(expense.date).toLocaleDateString("en-US", {
															year: "numeric",
															month: "2-digit",
															day: "2-digit",
														})
													: "No date"}
											</Col>
											<Col xs={1}>${expense.amount}</Col>
											<Col xs={2}>
												{expense.paidBy
													? allUser.find(
															(member) => member.fullname === expense.paidBy
														)?.fullname || ""
													: ""}
											</Col>
											<Col xs={1}>
												{expense.youLent ? `$${expense.youLent}` : ""}
											</Col>
											<Col xs={2} className="text-right">
												<PencilSquare
													className="mx-1 text-info"
													onClick={() => handleEditClick(expense)}
												/>
												<Trash
													className="mx-1 text-danger"
													onClick={() => handleDelete(expense.id)}
												/>
											</Col>
										</Row>
									</ListGroup.Item>
								)
							)}
						</React.Fragment>
					))}
				</ListGroup>
			</Card>
			<Button variant="info" onClick={handleModalShow}>
				<i className="bi bi-plus-lg" style={{ frontSize: "70px" }} />
				Add Expense
			</Button>
			<ExpenseModal
				show={modalShow}
				handleClose={handleModalClose}
				onSaveExpense={addNewExpense}
				expense={newExpenseForm}
				setExpense={setNewExpenseForm}
				availableTabs={["individual", "group"]}
				isGroupPage={false}
				friends={allUser}
				setFriends={setAllUser}
				allGroups={allGroups}
				currentUser={userInfo}
			/>
		</Container>
	);
}

export default Expense;

import { Button, Modal, Col, Row, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { PencilSquare, XSquare } from "react-bootstrap-icons";
import { API_BASE_URL } from "../../config";
import "./Profile.css";

// Profile that take display current user infomation and ability to edit user's info
function Profile(props) {
	// Get the access token from local storage
	const accessToken = localStorage.getItem("accessToken");
	const [user, setUser] = useState({});
	const [tempUser, setTempUser] = useState({ username: "", fullname: "" });

	// Define state variables for user data and edit mode for username and fullname
	const [isUsernameEditable, setIsUsernameEditable] = useState(false);
	const [isFullnameEditable, setIsFullnameEditable] = useState(false);

	// Send a PUT request to update user data on the server
	const saveChanges = async () => {
		try {
			const response = await axios.put(
				`${API_BASE_URL}/api/users/update`,
				{
					username: tempUser.username,
					fullname: tempUser.fullname,
				},
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				}
			);
			setUser(response.data);
			setIsUsernameEditable(false);
			setIsFullnameEditable(false);
		} catch (error) {
			console.error("Error updating user data:", error);
		}
	};

	// Send a GET request to fetch user data from the server
	const fetchUser = async (accessToken) => {
		try {
			const response = await axios.get(`${API_BASE_URL}/api/users`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			});
			setUser(response.data);
			setTempUser(response.data);
		} catch (error) {
			console.error("Error fetching user data:", error);
		}
	};

	// useEffect hook to fetch user data when the component mounts or when access token changes
	useEffect(() => {
		if (accessToken) {
			fetchUser(accessToken);
		}
	}, [accessToken]);

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Profile</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={3}>
							Full name:
						</Form.Label>
						<Col sm={7}>
							<div className={isFullnameEditable ? "" : "user-info"}>
								{isFullnameEditable ? (
									<Form.Control
										value={tempUser.fullname}
										onChange={(e) =>
											setTempUser({
												...tempUser,
												fullname: e.target.value,
											})
										}
									/>
								) : (
									<span>{user.fullname}</span>
								)}
							</div>
						</Col>
						<Col sm={2}>
							{isFullnameEditable ? (
								<XSquare
									className="custom-btn"
									variant="info"
									onClick={() => {
										setTempUser(user);
										setIsFullnameEditable(false);
									}}
								/>
							) : (
								<PencilSquare
									variant="info"
									onClick={() => {
										setTempUser({ ...tempUser, fullname: user.fullname });
										setIsFullnameEditable(true);
									}}
								/>
							)}
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={3}>
							Username:
						</Form.Label>
						<Col sm={7}>
							<div className={isFullnameEditable ? "" : "user-info"}>
								{isUsernameEditable ? (
									<Form.Control
										value={tempUser.username}
										onChange={(e) =>
											setTempUser({
												...tempUser,
												username: e.target.value,
											})
										}
									/>
								) : (
									<span>{user.username}</span>
								)}
							</div>
						</Col>
						<Col sm={2}>
							{isUsernameEditable ? (
								<XSquare
									variant="info"
									onClick={() => {
										setTempUser(user);
										setIsUsernameEditable(false);
									}}
								/>
							) : (
								<PencilSquare
									variant="info"
									onClick={() => {
										setTempUser(user);
										setIsUsernameEditable(true);
									}}
								/>
							)}
						</Col>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="info" onClick={() => {
					saveChanges()
					props.onHide()
				}}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default Profile;

import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Social.css";
import Highcharts from "highcharts";
import { allGroupsBarOptions } from "./chartOptions";
import axios from "axios";
import { API_BASE_URL } from "./../config";

function Data() {
	const [selectedGroup, setSelectedGroup] = useState({
		name: "All Groups",
		id: 0,
	});
	const [spentText, setSpentText] = useState("");

	const [allGroups, setGroup] = useState([]);
	const [userInfo, setUserInfo] = useState({ _id: null, fullname: "" });
	const accessToken = localStorage.getItem("accessToken");

	//getting the user information
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/users`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then((response) => {
				setUserInfo({
					id: response.data._id,
					fullname: response.data.fullname,
				});
			})
			.catch((error) => {
				console.error("Error fetching current user:", error);
			});
	}, [accessToken]);

	//getting the groups
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/groups?userId=${userInfo.id}`)
			.then((response) => {
				// handle success
				setGroup(
					response.data.map((groups) => ({
						...groups,
						id: groups._id, //rename the _id of mongoose to id for consistency
					}))
				);
			})
			.catch((error) => {
				// handle error
				console.log(error);
			});
	}, [userInfo]);

	//Graphs start here--------------------------------------------------------------------------------------

	//This sets up the bar chart for 'All Groups' and the bar chart for each of the specfic groups
	useEffect(() => {
		if (selectedGroup.name === "All Groups") {
			//calculate the group names for the x-axis and the total you lent for the y-axis for each group
			//also calulate the total you spent across all groups
			const groupIds = [];
			const totalYouLent = [];
			const groupmap = {};
			let totalSpent = 0;

			allGroups.forEach((group) => {
				const fetchExpenses = async (groupID) => {
					try {
						const response = await axios.get(
							`${API_BASE_URL}/api/expense/${groupID}`
						);
						response.data.forEach((expense) => {
							const groupId = expense.group;
							const index = groupIds.indexOf(groupId);

							//if the index for the current groupID doesnt alrdy exist in groupID[], then push it
							//push the absolute of the expense.youLent to totalYouLent[]
							if (index === -1) {
								groupIds.push(groupId);
								totalYouLent.push(Math.abs(expense.youLent));

								//else the index is alrdy in groupID so just add the expense to that groupID's index
							} else {
								totalYouLent[index] += Math.abs(expense.youLent);
							}

							//this is just an int variable to calculate the TOTAL regardless of groupID
							totalSpent += Math.abs(expense.youLent);
						});
					} catch (error) {
						console.error("Error fetching expenses: ", error);
						throw error;
					}

					//set the number for total spent across all groups displayed in the corner
					setSpentText("You spent $" + totalSpent + " in total");

					//get the container for the all groups bar chart
					//the reason only the all groups bar chart has this is cuz this graph is on the home page too
					const baroptions = allGroupsBarOptions;

					//set the y-axis labels and the x-axis data with the values we found earlier
					baroptions.xAxis.categories = allGroups.map((group) => group.name);
					baroptions.series[0].data = totalYouLent;

					Highcharts.chart("bar-chart-container", baroptions);
				};
				fetchExpenses(group.id);
			});
		} else {
			//this is the bar chart for each of the specific groups
			//the bar chart that shows the amount you spent for each of the expense categories
			const categories = [];
			const counts = [];
			const categorycounts = {};

			const fetchExpenses = async () => {
				try {
					//fetch expense data based on groupID
					const response = await axios.get(
						`${API_BASE_URL}/api/expense/${selectedGroup.id}`
					);

					//this is to calculate the total you lent in this group and display it
					let totalSpent = 0;
					response.data.map(
						(expense) => (totalSpent += Math.abs(expense.youLent))
					);
					setSpentText("You spent $" + totalSpent);

					//this is where you calculate the categories for the x-axis and the expense total for the y-axis
					response.data.forEach((expense) => {
						const category = expense.category;

						//if the current category isn't already in categorycounts, put it in
						//put in the expense you lent too to start it off
						if (!categorycounts[category]) {
							categorycounts[category] = Math.abs(expense.youLent);

							//if the current category is alrdy an index then just add the expense
							//you lent to it
						} else {
							categorycounts[category] += Math.abs(expense.youLent);
						}
					});

					//now it into two arrays, one with all the category names and another with the total
					//for each category
					for (const category in categorycounts) {
						categories.push(category);
						counts.push(categorycounts[category]);
					}
				} catch (error) {
					console.error("Error fetching expenses: ", error);
					throw error;
				}

				//set up the bar graph here and use categories and counts for the x and y axis
				const baroptions = {
					chart: {
						type: "column",
					},
					title: {
						text: "Expense Count in Each Category",
						margin: 40,
					},
					xAxis: {
						title: {
							text: "Category",
							margin: 20,
							style: {
								fontSize: "1em",
							},
						},
						categories: categories,
					},
					yAxis: {
						title: {
							text: "Amount of Money ($)",
							margin: 40,
							style: {
								fontSize: "1em",
							},
						},
					},
					series: [
						{
							name: "Data",
							showInLegend: false,
							data: counts,
						},
					],
				};

				Highcharts.chart("bar-chart-container", baroptions);
			};
			fetchExpenses();
		}
	}, [selectedGroup]);

	//This is for the line graph now
	//All groups shouldn't have a line graph so highcharts will send an empty line chart with no title
	useEffect(() => {
		if (selectedGroup.name === "All Groups") {
			const lineOptions = {
				title: "",
			};
			Highcharts.chart("line-chart-container", lineOptions);
		}

		//This is the line graph for each group
		else {
			//calculate the days for the x axis and total for each day for the y axis/data
			const days = [];
			const totals = [];
			const dailyTotals = {};

			const fetchExpenses = async () => {
				try {
					const response = await axios.get(
						`${API_BASE_URL}/api/expense/${selectedGroup.id}`
					);
					response.data.forEach((expense) => {
						const date = new Date(expense.date);

						//format from a time date to just a date where the month is the word and not a number
						//month-dd-yyyy
						const d = date.toLocaleDateString("en-US", {
							year: "numeric",
							month: "long",
							day: "2-digit",
						});

						//again if the current date isn't alrdy an index in dailytotals add it and with the expense as the value
						if (!dailyTotals[d]) {
							dailyTotals[d] = Math.abs(expense.youLent);

							//else just add the expense to the index we alrdy have
						} else {
							dailyTotals[d] += Math.abs(expense.youLent);
						}
					});

					//split into two arrays for x axis and data
					for (const d in dailyTotals) {
						days.push(d);
						totals.push(dailyTotals[d]);
					}
				} catch (error) {
					console.error("Error fetching expenses: ", error);
					throw error;
				}

				//set up the line graph and use days and totals for the data
				const lineOptions = {
					chart: {
						type: "line",
					},
					title: {
						text: "Amount Spent Over Time by Date",
					},
					xAxis: {
						title: {
							text: "Date",
							margin: 20,
							style: {
								fontSize: "1em",
							},
						},
						categories: days,
					},
					yAxis: {
						title: {
							text: "Amount of Money ($)",
							margin: 20,
							style: {
								fontSize: "1em",
							},
						},
					},
					series: [
						{
							name: "User",
							data: totals,
						},
					],
				};

				Highcharts.chart("line-chart-container", lineOptions);
			};
			fetchExpenses();
		}
	}, [selectedGroup]);

	return (
		<div className="app d-flex">
			<aside className="sidebar p-3">
				<Nav
					className="flex-column"
					style={{ width: "300px", fontSize: "1.1em" }}
				>
					<Nav.Link
						onClick={() => setSelectedGroup({ name: "All Groups", id: 0 })}
					>
						All Groups
					</Nav.Link>
					{allGroups.map(
						(group) =>
							group.members.includes(userInfo.id) && (
								<Nav.Link onClick={() => setSelectedGroup(group)}>
									{group.name}
								</Nav.Link>
							)
					)}
				</Nav>
			</aside>
			<main className="p-3 flex-grow-1">
				<h2 style={{ marginTop: "50px" }}>{spentText}</h2>
				<div
					id="bar-chart-container"
					style={{
						width: "60%",
						height: "300px",
						marginTop: "80px",
						marginBottom: "80px",
						marginLeft: "80px",
					}}
				></div>
				<div
					id="line-chart-container"
					style={{
						width: "60%",
						height: "300px",
						marginLeft: "80px",
					}}
				></div>
			</main>
		</div>
	);
}

export default Data;

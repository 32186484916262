import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import { Form, Button, Row } from "react-bootstrap";
import ToastNotification from "./ToastNotification";
import * as Yup from "yup";
import axios from "axios";
import logo from "../static/spendSmart_logo.png";
import "./Signup.css";
import { API_BASE_URL } from "./../config";

// Define a Yup schema for form validation
const SignupSchema = Yup.object().shape({
	fullname: Yup.string().required("Full name is required"),
	username: Yup.string().required("Username is required"),
	password: Yup.string()
		.min(5, "Password must be at least 5 characters")
		.required("Password is required"),
});

// Define the Signup functional component
const Signup = () => {
	const navigate = useNavigate();

	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [toastVariant, setToastVariant] = useState("success");

	const [showPassword, setShowPassword] = useState(false);

	// Function to toggle password visibility
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const displayToast = (message, variant) => {
		setToastMessage(message);
		setToastVariant(variant);
		setShowToast(true);
		setTimeout(() => setShowToast(false), 3000);
	};

	// Function to handle form submission
	const handleSubmit = async (
		values,
		{ setSubmitting, resetForm, setErrors }
	) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/users/signup`,
				values
			);
			displayToast(response.data.message, "success");
			resetForm();
			navigate("/signin");
		} catch (error) {
			if (error.response && error.response.status === 400) {
				console.log(error);
				displayToast(error.response.data.message, "danger");
			} else {
				console.log(error);
				setErrors({
					server: "An unexpected error occurred. Please try again later.",
				});
			}
		}
		setSubmitting(false);
	};

	return (
		<div>
			<div className="header-container">
				<h1 className="web-title">
					<img
						src={logo}
						alt="SpendSmart Logo"
						style={{ width: "200px", height: "auto" }}
					/>
				</h1>
				<Button
					className="signin-button"
					variant="info"
					onClick={() => navigate("/signin")}
				>
					Sign In
				</Button>
			</div>
			<div className="container">
				<h1 className="header">Sign Up</h1>
				<Formik
					initialValues={{ fullname: "", username: "", password: "" }}
					validationSchema={SignupSchema}
					onSubmit={handleSubmit}
				>
					{({ handleSubmit, setFieldValue, errors, touched }) => (
						<Form className="centered-form" noValidate onSubmit={handleSubmit}>
							<Form.Group as={Row} className="mb-2">
								<Form.Control
									type="text"
									name="fullname"
									placeholder="Full name"
									onChange={(e) => setFieldValue("fullname", e.target.value)}
									isInvalid={touched.fullname && !!errors.fullname}
								/>
								<ErrorMessage
									name="fullname"
									component={Form.Control.Feedback}
									type="invalid"
								/>
							</Form.Group>
							<Form.Group as={Row} className="mb-2">
								<Form.Control
									type="text"
									name="username"
									placeholder="Username"
									onChange={(e) => setFieldValue("username", e.target.value)}
									isInvalid={touched.username && !!errors.username}
								/>
								<ErrorMessage
									name="username"
									component={Form.Control.Feedback}
									type="invalid"
								/>
							</Form.Group>
							<Form.Group as={Row} className="mb-3">
								<div className="password-wrapper">
									<Form.Control
										type={showPassword ? "text" : "password"}
										name="password"
										onChange={(e) => setFieldValue("password", e.target.value)}
										isInvalid={touched.password && !!errors.password}
										placeholder="Password"
									/>
									<button
										type="button"
										className="toggle-password-btn"
										onClick={togglePasswordVisibility}
										aria-label="Toggle password visibility"
									>
										<i
											className={`bi ${
												showPassword ? "bi-eye-slash" : "bi-eye"
											}`}
										></i>
									</button>
								</div>
								{touched.password && errors.password && (
									<div className="invalid-feedback d-block">
										{errors.password}
									</div>
								)}
							</Form.Group>
							<Button variant="info" type="submit">
								Submit
							</Button>
						</Form>
					)}
				</Formik>
				<ToastNotification
					show={showToast}
					message={toastMessage}
					variant={toastVariant}
					onClose={() => setShowToast(false)}
				/>
			</div>
		</div>
	);
};

export default Signup;

import React from "react";
import Toast from "react-bootstrap/Toast";

// The Toast component from react-bootstrap is used to display the notification.
const ToastNotification = ({ show, message, variant = "danger", onClose }) => {
	return (
		<Toast
			onClose={onClose}
			show={show}
			delay={3000}
			autohide
			style={{
				position: "absolute",
				top: 20,
				right: 20,
			}}
		>
			<Toast.Header>
				<strong className={`mr-auto text-${variant}`}>Notification</strong>
			</Toast.Header>
			<Toast.Body>{message}</Toast.Body>
		</Toast>
	);
};

export default ToastNotification;

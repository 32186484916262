import "./App.css";
import React from "react";
import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,
	Outlet,
} from "react-router-dom";
import NavBar from "./Components/NavBar";
import Home from "./pages/Home";
import Signin from "./Components/Signin";
import Signup from "./Components/Signup";
import Expense from "./pages/Expense";
import Social from "./pages/Social";
import GroupExpense from "./pages/social/GroupExpense";
import Data from "./pages/Data";
import Footer from "./Components/Footer";

// Define the main App functional component
function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/signin" element={<Signin />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/" element={<Layout />}>
						<Route path="" element={<Home />} />
						<Route path="home" element={<Home />} />
						<Route path="expense" element={<Expense />} />
						<Route path="social" element={<Social />} />
						<Route
							path="social/groupExpense/:groupId/:totalOwed/:totalOwn"
							element={<GroupExpense />}
						/>
						<Route path="data" element={<Data />} />
					</Route>
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	);
}

// Define a Layout component to handle authenticated routes
function Layout() {
	const isAuth = localStorage.getItem("accessToken") !== null;
	if (!isAuth) return <Navigate to="/signin" />;
	return (
		<div>
			<NavBar />
			<Outlet />
		</div>
	);
}

export default App;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  FormControl,
  Dropdown,
  Form,
} from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import ExpenseModal from "../../Components/ExpenseModal";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DatePicker } from "react-rainbow-components";
import "../Expense.css";
import { API_BASE_URL } from "../../config";

const GroupExpense = () => {
  // Initializations of variables to be used
  const { groupId, totalOwed, totalOwn } = useParams();
  const [groupName, setGroupName] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [allGroups, setGroup] = useState([]);
  const [userInfo, setUserInfo] = useState({ _id: null, fullname: "" });
  const accessToken = localStorage.getItem("accessToken");
  const initialExpense = {
    title: "",
    involve: [],
    date: new Date(),
    amount: "",
    isGroup: true,
    group: "",
    paidBy: "",
    splitBetween: [{ user: "", amount: 0 }],
    recurring: "",
    category: "",
    youLent: 0,
  };
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => {
    setModalShow(false);
  };
  const handleModalShow = () => {
    setModalShow(true);
    setNewExpenseForm(initialExpense);
  };

  const [editExpenseId, setEditExpenseId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: "",
    date: "",
    amount: "",
    paidBy: "",
    youLent: "",
  });

  const [newExpenseForm, setNewExpenseForm] = useState(initialExpense);
  const [timeFrame, setTimeFrame] = useState("monthly");
  const handleTimeFrameChange = (e) => {
    setTimeFrame(e);
  };

  // This extract the current user of the acount
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/users`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setUserInfo({
          id: response.data._id,
          fullname: response.data.fullname,
        });
      })
      .catch((error) => {
        console.error("Error fetching current user:", error);
      });
  }, [accessToken]);

  // This get all the groups available within the user
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/groups?userId=${userInfo.id}`)
      .then((response) => {
        // handle success
        setGroup(response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }, []);

  // This get all the expenses corresponding to the group id
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/expense/${groupId}`
        );
        setExpenses(
          response.data
            .map((expense) => ({
              ...expense,
              id: expense._id, //rename the _id of mongoose to id for consistency
            }))
            .sort((a, b) => b.date - a.date)
        );
      } catch (error) {
        console.error("Error fetching expenses: ", error);
        throw error;
      }

      //fetch group data and store group name and group members' ids
      try {
        const groupData = await axios.get(
          `${API_BASE_URL}/api/groups/${groupId}`
        );
        setGroupName(groupData.data.name);
        const members = groupData.data.members;
        const updatedGroupMembers = [];
        // Fetch user data for each member and update the state
        for (const memberId of members) {
          try {
            const userResponse = await axios.get(
              `${API_BASE_URL}/api/users/find/${memberId}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            // Extract user name and ID
            const userData = userResponse.data;
            const userInfo = {
              id: userData._id, // Assuming user ID is available in userData
              fullname: userData.fullname, // Replace 'name' with the actual property name for the user's name
            };

            // Update the state with the new user information
            updatedGroupMembers.push(userInfo);
          } catch (error) {
            console.error(
              `Error fetching user information for ID ${memberId}:`,
              error
            );
          }
        }

        // After fetching information for all group members, update the state
        setGroupMembers(updatedGroupMembers);
      } catch (error) {
        console.error("Error fetching group information:", error);
      }
    };

    fetchExpenses();
  }, [groupId]);

  // This sort expenses by date
  const sortExpensesByDateDesc = (expensesArray) => {
    return expensesArray.sort((a, b) => b.date - a.date);
  };

  // This group the expenses to use for filter by month, week, and year
  const groupedExpenses = useMemo(() => {
    return expenses.reduce((acc, expense) => {
      const date = new Date(expense.date);
      let period;

      switch (timeFrame) {
        case "monthly":
          period = date.toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          break;
        case "yearly":
          period = date.getFullYear().toString();
          break;
        default:
          period = `Week of ${date.getDate()} - ${
            date.getDate() + 7
          }, ${date.getFullYear()}`;
      }

      if (!acc[period]) {
        acc[period] = [];
      }
      acc[period].push(expense);
      return acc;
    }, {});
  }, [expenses, timeFrame]);

  // This sorted the expenses by date
  const sortedExpenses = useMemo(() => {
    return expenses.sort((a, b) => b.date - a.date);
  }, [expenses]);

  // This add the new expense
  const addNewExpense = useCallback(async (newExpense) => {
    setExpenses((prevExpenses) => [...prevExpenses, newExpense]);
  });

  // This function put all expenses within the curr week into an array
  const currWeekExpensesList = () => {
    const now = new Date();
    // Reset the hours, minutes, and seconds to 0 for the start of the day
    now.setHours(0, 0, 0, 0);

    let dayOfWeek = now.getDay();
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

    // Calculate first and last day of the current week
    const firstDayOfWeek = new Date(now.setDate(now.getDate() - dayOfWeek + 1));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    // Set the last day of the week to the end of the day
    lastDayOfWeek.setHours(23, 59, 59, 999);

    // Filter expenses for the current week
    const currWeekExpenses = expenses.filter((expense) => {
      const expenseDate = new Date(expense.date);
      return expenseDate >= firstDayOfWeek && expenseDate <= lastDayOfWeek;
    });
    return currWeekExpenses;
  };

  //This function calculate the total expenses of this week
  const totalWeekExpense = (weekExpenses) => {
    let sum = 0;
    weekExpenses.map((expense) => {
      expense.splitBetween.forEach((each) => {
        if (each.user === userInfo.id) {
          sum += each.amount;
        }
      });
    });
    return parseFloat(sum.toFixed(2));
  };

  // This calculate the week spending
  const [weekSpending, setWeekSpending] = useState();
  useEffect(() => {
    if (groupId) {
      const currentWeekExpenses = currWeekExpensesList();
      setWeekSpending(totalWeekExpense(currentWeekExpenses));
    }
  }, [expenses, groupId]);

  // This handle the edit button to change the form
  const handleEditClick = (expense) => {
    setEditExpenseId(expense.id);
    const formValues = {
      title: expense.title,
      date: expense.date,
      amount: expense.amount,
      paidBy: expense.paidBy,
      youLent: expense.youLent,
    };
    setEditFormData(formValues);
  };

  // This handle the edited form change
  const handleEditFormChange = (event) => {
    const { name, value } = event.target
      ? event.target
      : { name: "date", value: event };
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };

  // Update the expense
  const handleSaveClick = useCallback(
    async (id) => {
      try {
        // Find the full name from groupMembers based on the paidBy ID
        const user = groupMembers.find(
          (member) => member.id === editFormData.paidBy
        );
        const userName = user ? user.fullname : "Unknown User";

        // Include the full name in the data sent to the server
        const updatedData = {
          ...editFormData,
          date: new Date(editFormData.date),
          paidByName: userName, // Include this field to send to the server if needed
        };

        const response = await axios.put(
          `${API_BASE_URL}/api/expense/handleUpdate/${id}`,
          updatedData
        );

        // Update locally, make sure to update the name field that is displayed in the list
        setExpenses((prevExpenses) =>
          prevExpenses.map((expense) =>
            expense.id === id
              ? {
                  ...response.data,
                  id: response.data._id,
                  paidByName: userName,
                } // Update the name here
              : expense
          )
        );
        setEditExpenseId(null);
      } catch (error) {
        console.error("Error updating expense:", error);
      }
    },
    [editFormData, groupMembers]
  );

  // Delete the expense
  const handleDelete = useCallback(async (id) => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
      try {
        await axios.delete(`${API_BASE_URL}/api/expense/${id}`);
        setExpenses((prevExpenses) =>
          prevExpenses.filter((expense) => expense.id !== id)
        );
      } catch (error) {
        console.error("Error deleting expense:", error);
      }
    }
  }, []);

  // This sort expenses by date
  const sortedPeriods = Object.keys(groupedExpenses).sort((a, b) => {
    const dateA = new Date(a.split(" ").pop());
    const dateB = new Date(b.split(" ").pop());
    return dateB - dateA;
  });

  return (
    <>
      <Container className="my-3">
        <Row>
          <h2> {groupName} </h2>
        </Row>
        <Row>
          <div className="expenses-header">
            <div className="expenses-week">
              <h5>
                <span>Expenses This Week: ${weekSpending}</span>
                <span class="total-own">Total Own: ${totalOwn}</span>
                <span class="total-owe">
                  Total Owe: -${Math.abs(totalOwed)}
                </span>
              </h5>
            </div>
          </div>
        </Row>
        <Row xs="auto" style={{ marginBottom: "20px" }}>
          <Dropdown onSelect={handleTimeFrameChange}>
            <Dropdown.Toggle variant="info" id="dropdown-timeframe">
              {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="weekly">Weekly</Dropdown.Item>
              <Dropdown.Item eventKey="monthly">Monthly</Dropdown.Item>
              <Dropdown.Item eventKey="yearly">Yearly</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Row>
        <Card>
          <Card.Header>
            <Row>
              <Col xs={1}>Category</Col>
              <Col xs={2}>Title</Col>
              <Col xs={3}>Date</Col>
              <Col xs={1}>Amount</Col>
              <Col xs={2}>Paid By</Col>
              <Col xs={1}>You Lent</Col>
              <Col xs={2}>Actions</Col>
            </Row>
          </Card.Header>
          <ListGroup variant="flush">
            {sortedPeriods.map((period) => (
              <React.Fragment key={period}>
                {Object.entries(groupedExpenses).length !== 0 && (
                  <ListGroup.Item bg="secondary" className="text-start">
                    <strong>{period}</strong>
                  </ListGroup.Item>
                )}
                {groupedExpenses[period].map((expense) =>
                  editExpenseId === expense.id ? (
                    <ListGroup.Item key={expense.id}>
                      <Row>
                        <Col xs={1}>
                          <Form.Control
                            as="select"
                            value={editFormData.category}
                            onChange={handleEditFormChange}
                            name="category"
                            required
                          >
                            <option value="">Select...</option>
                            <option value="groceries">Groceries</option>
                            <option value="utilities">Utilities</option>
                            <option value="rent">Rent</option>
                            <option value="transportation">
                              Transportation
                            </option>
                            <option value="food">Food</option>
                            <option value="other">Other</option>
                          </Form.Control>
                        </Col>

                        <Col xs={2}>
                          <FormControl
                            type="text"
                            name="title"
                            value={editFormData.title}
                            onChange={handleEditFormChange}
                          />
                        </Col>
                        <Col xs={3}>
                          <DatePicker
                            id="datePicker-1"
                            value={editFormData.date}
                            onChange={handleEditFormChange}
                            alt="Cancel"
                          />
                        </Col>
                        <Col xs={1}>
                          <FormControl
                            type="number"
                            name="amount"
                            value={editFormData.amount}
                            onChange={handleEditFormChange}
                          />
                        </Col>
                        <Col xs={2}>
                          <Form.Control
                            as="select"
                            value={editFormData.paidBy}
                            onChange={handleEditFormChange}
                            name="paidBy"
                          >
                            {expense.splitBetween.map((split) => {
                              // Find the user in groupMembers by ID and display the user's full name
                              const user = groupMembers.find(
                                (member) => member.id === split.user
                              );
                              return (
                                <option
                                  key={split.user.fullname}
                                  value={split.user.fullname}
                                >
                                  {user ? user.fullname : "Unknown User"}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                        <Col xs={1}>
                          <FormControl
                            type="number"
                            name="youLent"
                            value={editFormData.youLent}
                            onChange={handleEditFormChange}
                          />
                        </Col>
                        <Col xs={2} className="text-start">
                          <Button
                            variant="info"
                            onClick={() => handleSaveClick(expense.id)}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item key={expense.id}>
                      <Row>
                        <Col xs={1}>
                          {expense.category === "groceries" && (
                            <i className="bi bi-shop"></i>
                          )}
                          {expense.category === "utilities" && (
                            <i className="bi bi-thermometer-snow"></i>
                          )}
                          {expense.category === "rent" && (
                            <i className="bi bi-houses"></i>
                          )}
                          {expense.category === "transportation" && (
                            <i className="bi bi-bus-front"></i>
                          )}
                          {expense.category === "food" && (
                            <i className="bi bi-cup-hot"></i>
                          )}
                          {expense.category === "other" && (
                            <i className="bi bi-puzzle-fill"></i>
                          )}
                        </Col>
                        <Col xs={2} className="text-start">
                          {expense.title}
                        </Col>
                        <Col xs={3}>
                          {expense.date
                            ? new Date(expense.date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )
                            : "No date"}
                        </Col>
                        <Col xs={1}>${expense.amount}</Col>
                        <Col xs={2}>
                          {console.log("PAID BY", expense.paidBy)}
                          {
                            groupMembers.find(
                              (member) => member.fullname === expense.paidBy
                            )?.fullname
                          }
                        </Col>
                        <Col xs={1}>
                          {expense.youLent ? `$${expense.youLent}` : ""}
                        </Col>
                        <Col xs={2} className="text-right">
                          <PencilSquare
                            className="mx-1 text-info"
                            onClick={() => handleEditClick(expense)}
                          />
                          <Trash
                            className="mx-1 text-danger"
                            onClick={() => handleDelete(expense.id)}
                          />
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )
                )}
              </React.Fragment>
            ))}
          </ListGroup>
        </Card>
      </Container>
      <Button variant="info" onClick={handleModalShow}>
        <i className="bi bi-plus-lg" style={{ frontSize: "70px" }} />
        Add Expense
      </Button>
      <ExpenseModal
        show={modalShow}
        handleClose={handleModalClose}
        onSaveExpense={addNewExpense}
        expense={newExpenseForm}
        setExpense={setNewExpenseForm}
        availableTabs={["group"]}
        isGroupPage={true}
        friends={groupMembers}
        setFriends={setGroupMembers}
        allGroups={allGroups}
        currentUser={userInfo}
        groupId={groupId}
      />
    </>
  );
};

export default GroupExpense;

import React, { useState, useEffect } from "react";
import {
	Nav,
	Form,
	Card,
	Modal,
	Button,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import "./Social.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import TagsInput from "../Components/TagsInput";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config";

//This class is associated with the display and fetching data for the social page
function Social() {
	const [selectedGroup, setSelectedGroup] = useState("All Groups");
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);

	const [isFavorite, setIsFavorite] = useState(false); // new state for checkbox
	const [newGroupName, setGroupName] = useState("");
	const [newDescription, setDescription] = useState("");
	const [newMembers, setNewMembers] = useState([]); //to set members for the group FROM the friend list

	const [groupsData, setGroup] = useState([]);
	const accessToken = localStorage.getItem("accessToken");
	const [user, setUser] = useState({});

	//fetches the information about the current user
	const fetchUser = async (accessToken) => {
		try {
			const response = await axios.get(`${API_BASE_URL}/api/users`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			});
			setUser(response.data);
		} catch (error) {
			console.error("Error fetching user data:", error);
		}
	};

	//updates the access token
	useEffect(() => {
		if (accessToken) {
			fetchUser(accessToken);
		}
	}, [accessToken]);

	//this gets the social groups they are in based on the current user
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/groups?userId=${user._id}`)
			.then((response) => {
				setGroup(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [user]);

	const [memberData, setMember] = useState([]); // the friend list
	const [userData, setUsers] = useState([]); //list of object containing both id and fullname

	//this gets the information about the users friend list
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/users/all`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then((response) => {
				// handle success
				let names = [user.fullname, user.username];
				user.friendList?.forEach((friend) => {
					response.data.forEach((u) => {
						if (friend === u._id) {
							names.push(u.fullname, u.username);
						}
					});
				});

				setMember(names);
				setUsers(response.data);
			})
			.catch((error) => {
				// handle error
				console.log(error);
			});
	}, [accessToken, user]);

	//this gets the data about the money the user owns
	const [ownData, setOwn] = useState([]);
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/groups/totalOwn`)
			.then((response) => {
				// handle success
				setOwn(response.data);
			})
			.catch((error) => {
				// handle error
				console.log(error);
			});
	}, []);

	//this gets the data about the money the user owes
	const [owedData, setOwed] = useState([]);
	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/api/groups/totalOwed`)
			.then((response) => {
				// handle success
				setOwed(response.data);
			})
			.catch((error) => {
				// handle error
				console.log(error);
			});
	}, []);

	//This creates a group and posts it into the database, it ensures that the group has to contain more than one member
	function newGroup(newName, newDescription, favorite, newMembers) {
		var newGroup = {};
		let memberList = [];

		if (newMembers.length > 0) {
			newMembers.push(user.fullname);
			const newMembersID = newMembers.map((member) => {
				const userID = userData.find(
					(user) =>
						user.username.toLowerCase().includes(member.toLowerCase()) ||
						user.fullname.toLowerCase().includes(member.toLowerCase())
				);
				return userID ? userID._id : null;
			});
			memberList = [...new Set(newMembersID)];

			newGroup = {
				name: newName,
				description: newDescription,
				owed: 0,
				own: 0,
				favorite: favorite,
				members: memberList,
			};
		}

		axios
			.post(`${API_BASE_URL}/api/groups`, newGroup)
			.then((response) => {
				setGroup([...groupsData, response.data]);
			})
			.catch((error) => {
				console.error("Error adding new social group:", error);
			});
	}

	//This function helps with the display of the groups on the page and is called in the html code below
	//This also helps calculate the total own and owe of the current user within that group
	//This also helps in filtering out the groups based on if they are marked as favorite or not when created
	function getGroupsToShow() {
		// Update total own from expenses of the group
		groupsData.map((x) => {
			x.own = 0;
			return ownData.forEach((y) => {
				if (y.group === x._id && y.paidBy === user.fullname) {
					x.own = x.own + y.amount;
					return x;
				}
			});
		});

		//Update the total owe from expense of the group
		groupsData.map((x) => {
			x.owed = 0;
			let total1 = 0;
			let total2 = 0;
			return owedData.forEach((z) => {
				if (z.group === x._id) {
					if (z.paidBy === user.fullname) {
						z.splitBetween.forEach((s) => {
							if (s.user !== user._id) {
								total1 = total1 + s.amount;
							}
						});
					} else {
						z.splitBetween.forEach((s) => {
							if (s.user === user._id) {
								total2 = total2 + s.amount;
							}
						});
					}
					x.owed = total1 - total2;
					return x;
				}
			});
		});

		switch (selectedGroup) {
			case "Favorite Groups":
				return groupsData.filter(
					(g) =>
						g.favorite && g.members.filter((m) => m === user._id).length !== 0
				);
			case "All Groups":
				return groupsData.filter(
					(g) => g.members.filter((m) => m === user._id).length !== 0
				);
			default:
				return [];
		}
	}

	//This resets the values in the modal to empty when the modal is closed or cancelled
	const resetForm = () => {
		setIsFavorite(false);
		setGroupName("");
		setDescription("");
		setNewMembers([]);
		// Add any other form states you want to reset here
	};

	//This handles how the modal closes
	const handleClose = () => {
		setShow(false);
		// call the resetForm function when closing the modal
		resetForm();
	};

	return (
		<Container>
			<div className="app d-flex">
				{/* <aside className="sidebar p-3"> */}
				{/* <div className="search mb-3">
        <FormControl type="text" placeholder="Search" />
      </div> */}
				<Nav className="flex-column" style={{ width: "300px" }}>
					<button
						type="button"
						className="btn btn-outline-secondary"
						onClick={handleShow}
					>
						<i
							className="bi bi-journal-plus"
							style={{ fontSize: "20px", marginRight: "10px" }}
						/>
						Add New Group
					</button>
					<Nav.Link onClick={() => setSelectedGroup("All Groups")}>
						All Groups
					</Nav.Link>
					<Nav.Link onClick={() => setSelectedGroup("Favorite Groups")}>
						Favorite Groups
					</Nav.Link>
				</Nav>
				{/* </aside> */}

				<div className="p-3 flex-grow-1">
					{getGroupsToShow().map((group) => (
						<Card key={group.id} className="group_card">
							<Card.Header as="h3" className="group_title">
								{group.name}
							</Card.Header>
							<Card.Body>
								<Row>
									<Col md={6}>
										<Card.Text>{group.description}</Card.Text>
									</Col>
									<Col md={6}>
										<div>
											<Card.Title>Total Owed:</Card.Title>
											<Card.Text>${group.owed}</Card.Text>
										</div>
										<div>
											<Card.Title>Total Own:</Card.Title>
											<Card.Text>${group.own}</Card.Text>
										</div>
									</Col>
								</Row>
								<Button
									as={Link}
									to={`/social/groupExpense/${group._id}/${group.owed}/${group.own}`}
									style={{ float: "left" }}
									variant="outline-info"
								>
									Go to Group
								</Button>
							</Card.Body>
						</Card>
					))}

					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Add Group</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlInput1"
								>
									<Form.Label>Group Name</Form.Label>
									<Form.Control
										type="group_name"
										placeholder=""
										autoFocus
										value={newGroupName}
										onChange={(e) => setGroupName(e.target.value)}
									/>
								</Form.Group>
								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlTextarea1"
								>
									<Form.Label>Group Description</Form.Label>
									<Form.Control
										as="textarea"
										rows={3}
										value={newDescription}
										onChange={(e) => setDescription(e.target.value)}
									/>
								</Form.Group>

								<div>
									<Form.Label>Select Members</Form.Label>
									<TagsInput
										allowedNames={memberData}
										setMembers={setNewMembers}
									/>
								</div>

								<div style={{ margin: "20px 0" }}>
									<Form.Check
										type={"checkbox"}
										label={`Favorite`}
										id={`favorite_group`}
										checked={isFavorite}
										onChange={() => setIsFavorite(!isFavorite)}
									/>
								</div>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
							<Button
								variant="primary"
								onClick={() => {
									newGroup(
										newGroupName,
										newDescription,
										isFavorite,
										newMembers
									);
									handleClose();
								}}
							>
								Create
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		</Container>
	);
}

export default Social;

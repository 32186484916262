import { Form, Modal, Button } from "react-bootstrap";

function Settings(props) {
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4>Notification</h4>
				<Form>
					<Form.Check
						type="switch"
						id="custom-switch"
						label="Check this switch"
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default Settings;

import { useState, useEffect } from "react";

//used to create the tags around the names that are inputted into the members text box
function TagsInput({ allowedNames, setMembers }) {
	const [tags, setTags] = useState([]);
	const [warning, setWarning] = useState(""); // to handle the warning message

	//the conditions that have to be met in order create teh tag
	function handleKeyDown(e) {
		if (e.key !== "Enter") return;
		const value = e.target.value;

		const isAllowed = allowedNames.some(
			(name) => name.toLowerCase() === value.toLowerCase() // case-insensitive comparison
		);

		if (!value.trim() || tags.includes(value)) {
			setWarning(""); // clear any previous warnings
			return;
		}

		if (!isAllowed) {
			setWarning(
				`${value} is not on the list of friends. Please enter a different name.`
			);
			e.target.value = "";
			return;
		}

		setTags((prevTags) => [...prevTags, value]);
		setWarning(""); // clear any previous warnings
		e.target.value = "";
	}

	//this helps with removing the name in the members text box when you click the x on the tag
	function removeTag(tagToRemove) {
		setTags((tags) => tags.filter((tag) => tag !== tagToRemove));
	}

	// When tags change, use the prop function to pass them back up
	useEffect(() => {
		setMembers(tags);
	}, [tags, setMembers]);

	return (
		<div className="tags-input-container">
			{tags.map((tag, index) => (
				<div className="tag-item" key={index}>
					<span className="text">{tag}</span>
					<span className="close" onClick={() => removeTag(tag)}>
						&times;
					</span>
				</div>
			))}
			<input
				onKeyDown={handleKeyDown}
				type="text"
				className="tags-input"
				placeholder="Type something"
			/>
			{warning && (
				<div style={{ color: "red", marginTop: "10px" }}>{warning}</div>
			)}
		</div>
	);
}

export default TagsInput;
